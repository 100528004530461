<template>
  <div>
    <div class="d-flex">
      <el-select :value="selected" @input="dispatch" :multiple="multiple" :placeholder="placeholder || $t('message.client')" filterable remote :remote-method="searchClientsWithLoad"
        clearable :size="size" class="w-100" :disabled="disabled" :loading="loadingData">
        <el-option v-for="(item, index) in itemList" :key="'items-' + index" 
          :label="((item.custom_id ? (item.custom_id + ' | ') : '') + item.name)" 
          :value="item.id">
          {{item.custom_id ? (item.custom_id + ' | ') : ''}} {{item.name}} 
          <span v-if="showLimitsByRole" style="opacity: 0.7"> | {{ item.country ? item.country.name : '' }} | {{ item.phone }}</span>
        </el-option>
      </el-select>
      <div class="d-flex ml-3">
        <el-button v-if="(create === true) && permissions.some(per => per.slug == 'clients.create')" :size="size" type="primary" icon="el-icon-plus" @click="drawerCreate = true" plain circle></el-button>
        <el-button v-if="selected && address" :size="size" type="primary" icon="el-icon-location-information" @click="drawerClientAddress = true" plain circle></el-button>
        <el-button v-if="selected && (update === true) && permissions.some(per => per.slug == 'clients.update')" :size="size" type="primary" icon="el-icon-edit" @click="updateClient()" plain circle></el-button>
      </div>
    </div>
    <!-- Create -->
    <div class="app-modal app-modal__full modal-color-bg">
      <el-drawer
          :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
          v-if="create === true" 
          :with-header="false"
          :visible.sync="drawerCreate"
          ref="drawerCreate"
          size="70%"
          class="bg-se"
          :append-to-body="true"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
      >
          <div>
              <crm-create
                @newInventoryItem="newInventoryItem"
                :title="placeholder || $t('message.client')"
                ref="drawerCreateChild"
                drawer="drawerCreate"
              >
              </crm-create>
          </div>
      </el-drawer>

      <el-drawer
          :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
          v-if="update === true" 
          :with-header="false"
          :visible.sync="drawerUpdate"
          ref="drawerUpdate"
          class="bg-se"
          size="70%"
          :append-to-body="true"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
      >
          <div>
              <crm-update
                :selectedItem="selectedItem"
                :title="placeholder || $t('message.client')"
                @newInventoryItem="newInventoryItem"
                ref="drawerUpdateChild"
                drawer="drawerUpdate"
              >
              </crm-update>
          </div>
      </el-drawer>
      
      <el-drawer
          :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
          v-if="update === true" 
          :with-header="false"
          :visible.sync="drawerClientAddress"
          ref="drawerClientAddress"
          size="70%"
          class="bg-se"
          :append-to-body="true"
          @opened="drawerOpened('drawerClientAddressChild')"
          @closed="drawerClosed('drawerClientAddressChild')"
      >
          <div>
              <client-address
                :client_id="selected"
                :deal_id="deal_id"
                :type="'deal'"
                v-model="deal_address"
                ref="drawerClientAddressChild"
                drawer="drawerClientAddress"
              >
              </client-address>
          </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import CrmCreate from "@/views/clients/components/crm-create";
import CrmUpdate from "@/views/clients/components/crm-update";
import clientAddress from "@/views/clients/components/clientAddress/clientAddress";
import { mapGetters, mapActions } from "vuex";
import select_create_drawer from "@/utils/mixins/select_create_drawer";
import _ from "lodash";

export default {
  mixins: [select_create_drawer],
  components: {
    CrmCreate,
    CrmUpdate,
    clientAddress
  },
  props: {
    size: {
      default: "small"
    },
    placeholder: {
      default: null
    },
    id: {
      default: null
    },
    disabled: {
      default: false
    },
    create: {
      default: false
    },
    update: {
      default: false
    },
    address: {
      default: false
    },
    deal_id: {
      default: null
    },
    role_slug: {},
    query: {
      default: Object
    },
    multiple: {
      default: false
    }
  },
  watch: {
    id: {
      handler(newVal) {
        this.selected = newVal;
      },
      immediate: true,
      deep: true
    },
    deal_address: {
      handler(el) {
        if (el && el.id) {
          this.$emit("updateDealAddressID", el);
        }
      },
      immediate: true,
      deep: true
    },
    role_slug: {
      handler(e) {
        this.loadingData = true;
        if (e == "cabinet") {
          this.updateDebounce({ except_clientType_id: 1 });
        } else if (e && e != "cabinet") {
          this.updateDebounce(this.query);
        }
      },
      immediate: true,
      deep: true
    },
    query: {
      handler(oldVal, newVal) {
        if (JSON.stringify(oldVal) != JSON.stringify(newVal)) {
          this.updateDebounce(this.query);
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      selected: null,
      drawerClientAddress: false,
      itemList: [],
      deal_address: null,
      loadingData: false,
      // Track the latest search term so outdated responses can be ignored.
      currentSearch: "",
      updateDebounce: _.debounce(function(query) {
        this.inventorySearch(query)
          .then(() => {
            this.itemList = JSON.parse(JSON.stringify(this.inventoryItems));
            if (this.id) {
              let client = this.itemList.find(el => el.id === this.id);
              if (client) {
                this.$emit("updateClientCridentials", client);
                this.selectedItem = JSON.parse(JSON.stringify(client));
              }
            }
            this.loadingData = false;
          })
          .catch(() => {
            this.loadingData = false;
          });
      }, 1000)
    };
  },
  computed: {
    ...mapGetters({
      inventoryItems: "clients/searchInventory",
      all_inventory: "clients/inventory",
      permissions: "auth/permissions",
      selectedClient: "clients/model",
      role: "auth/role",
      mode: "MODE"
    }),
    showLimitsByRole() {
      if (this.role.slug && this.role.slug.includes("warehouse")) {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...mapActions({
      show: "clients/show",
      updateInventory: "clients/inventory",
      inventorySearch: "clients/inventorySearch"
    }),
    // Called when the remote search is triggered.
    searchClientsWithLoad(val) {
      this.loadingData = true;
      // Update currentSearch with the latest value.
      this.currentSearch = val;
      this.searchClients(val);
    },
    updateClient() {
      if (this.selectedItem && this.selectedItem.id && this.selected) {
        this.drawerUpdate = true;
      } else if (this.selected) {
        this.show(this.selected)
          .then(() => {
            setTimeout(() => {
              this.selectedItem = JSON.parse(JSON.stringify(this.selectedClient));
            }, 100);
            this.drawerUpdate = true;
          })
          .catch(() => {
            this.$notify({
              title: this.$t("message.warning"),
              type: "warning",
              offset: 130,
              message: this.$t("message.client_not_found")
            });
          });
      }
    },
    // Debounced search function that tracks the local search term.
    searchClients: _.debounce(function(val) {
      let query = this.query;
      if (this.role_slug == "cabinet") {
        // Remove partners from client list.
        query = { except_clientType_id: 1 };
      }
      if (val) {
        query = { search: val, ...query };
      }
      // Capture the current search term for this request.
      const localSearch = val;
      this.updateItemsList(query, localSearch);
    }, 1000),
    dispatch(e) {
      if (this.itemList && this.itemList.length > 0 && e && !Array.isArray(e)) {
        let client = this.itemList.find(el => el.id === e);
        if (client) {
          this.selectedItem = JSON.parse(JSON.stringify(client));
          this.$emit("updateClientCridentials", client);
          this.$emit("getClient", client);
        } else {
          this.show(e)
            .then(() => {
              this.selectedItem = JSON.parse(JSON.stringify(this.selectedClient));
              this.$emit("updateClientCridentials", this.selectedClient);
              this.$emit("getClient", this.selectedClient);
            })
            .catch(() => {
              this.$notify({
                title: this.$t("message.warning"),
                type: "warning",
                offset: 130,
                message: this.$t("message.client_not_found")
              });
            });
        }
      } else {
        this.selectedItem = {};
      }
      this.selected = e;
      this.$emit("input", e);
    },
    newInventoryItem(newItem) {
      this.loadingData = true;
      this.inventorySearch(this.query)
        .then(() => {
          if (this.inventoryItems && this.inventoryItems.length > 0) {
            this.itemList = JSON.parse(JSON.stringify(this.inventoryItems));
            setTimeout(() => {
              this.dispatch(newItem.id);
            }, 300);
          }
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    // Update the item list and check that the search result corresponds to the current search term.
    updateItemsList(query, localSearch = null) {
      this.inventorySearch(query)
        .then(() => {
          // Only update if the local search matches the current search term.
          if (localSearch !== null && localSearch !== this.currentSearch) {
            // Outdated response; ignore it.
            return;
          }
          this.itemList = JSON.parse(JSON.stringify(this.inventoryItems));
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    }
  }
};
</script>
